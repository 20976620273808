import Header from '../../Header'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'

const Doggos = (props) => {
  return (
    <>
      {/* Top level navigation */}
      <Header />

      {/* Site / Body */}
      <div className={style.body}>
        <div className={style.content}>{props.children}</div>

        {/* Footer */}
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <div className={style.footerLinks}>
              <div>
                <h4>Cities we're in</h4>
                <ul>
                  <li>
                    <p>
                      <Link to='/search/'>Las Vegas</Link>
                      <span className={style.betaBadge}>Beta</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Phoenix <span>(Coming soon)</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className={style.companyLinks}>
                <h4>Company</h4>
                <ul>
                  <li>
                    <p>
                      <Link className={style.sitterLink} to={'/about'}>
                        About
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link
                        className={style.sitterLink}
                        to={'/become-a-sitter'}
                      >
                        Become a sitter
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link className={style.sitterLink} to={'/careers'}>
                        Careers
                      </Link>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.footerCompanyDetails}>
              <div className={style.text}>2024 © doggos™</div>
              <div className={style.linkContainer}>
                <Link to='/terms/'>Terms</Link>
                <Link to='/privacy/'>Privacy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Doggos
